var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ck-wysiwyg", class: { "ck-wysiwyg--lg": _vm.large } },
    [
      _c("editor-menu-bar", {
        staticClass: "ck-wysiwyg__toolbar",
        attrs: { editor: _vm.editor },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var commands = ref.commands
              var isActive = ref.isActive
              return _c(
                "div",
                { staticClass: "ck-wysiwyg__toolbar-buttons-wrapper" },
                [
                  isActive.bold
                    ? _c(
                        "gov-button",
                        {
                          staticClass: "ck-wysiwyg__toolbar-button",
                          attrs: { active: isActive.bold(), title: "Bold" },
                          on: { click: commands.bold }
                        },
                        [_c("i", { staticClass: "fas fa-bold" })]
                      )
                    : _vm._e(),
                  isActive.italic
                    ? _c(
                        "gov-button",
                        {
                          staticClass: "ck-wysiwyg__toolbar-button",
                          attrs: { active: isActive.italic(), title: "Italic" },
                          on: { click: commands.italic }
                        },
                        [_c("i", { staticClass: "fas fa-italic" })]
                      )
                    : _vm._e(),
                  isActive.heading
                    ? _c(
                        "gov-button",
                        {
                          staticClass: "ck-wysiwyg__toolbar-button",
                          attrs: {
                            active: isActive.heading({ level: 1 }),
                            title: "Heading 1"
                          },
                          on: {
                            click: function($event) {
                              return commands.heading({ level: 1 })
                            }
                          }
                        },
                        [_vm._v("\n        H1\n      ")]
                      )
                    : _vm._e(),
                  isActive.heading
                    ? _c(
                        "gov-button",
                        {
                          staticClass: "ck-wysiwyg__toolbar-button",
                          attrs: {
                            active: isActive.heading({ level: 2 }),
                            title: "Heading 2"
                          },
                          on: {
                            click: function($event) {
                              return commands.heading({ level: 2 })
                            }
                          }
                        },
                        [_vm._v("\n        H2\n      ")]
                      )
                    : _vm._e(),
                  isActive.heading
                    ? _c(
                        "gov-button",
                        {
                          staticClass: "ck-wysiwyg__toolbar-button",
                          attrs: {
                            active: isActive.heading({ level: 3 }),
                            title: "Heading 3"
                          },
                          on: {
                            click: function($event) {
                              return commands.heading({ level: 3 })
                            }
                          }
                        },
                        [_vm._v("\n        H3\n      ")]
                      )
                    : _vm._e(),
                  isActive.bullet_list
                    ? _c(
                        "gov-button",
                        {
                          staticClass: "ck-wysiwyg__toolbar-button",
                          attrs: {
                            active: isActive.bullet_list(),
                            title: "Bullet list"
                          },
                          on: { click: commands.bullet_list }
                        },
                        [_c("i", { staticClass: "fas fa-list-ul" })]
                      )
                    : _vm._e(),
                  isActive.ordered_list
                    ? _c(
                        "gov-button",
                        {
                          staticClass: "ck-wysiwyg__toolbar-button",
                          attrs: {
                            active: isActive.ordered_list(),
                            title: "Ordered list"
                          },
                          on: { click: commands.ordered_list }
                        },
                        [_c("i", { staticClass: "fas fa-list-ol" })]
                      )
                    : _vm._e(),
                  isActive.link
                    ? _c(
                        "gov-button",
                        {
                          staticClass: "ck-wysiwyg__toolbar-button",
                          attrs: { active: isActive.link(), title: "Link" },
                          on: {
                            click: function($event) {
                              commands.link({ href: _vm.promptUrl() })
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-link" })]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c("editor-content", {
        staticClass: "ck-wysiwyg__content",
        attrs: { editor: _vm.editor }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }